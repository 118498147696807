import { Component, Input } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Practice } from '../../../shared/models/practice';
import { PlaceholderSectionElementMap } from '../../../shared/models/editor-with-section/placeholder-section';
import { SectionPlaceholderResolver } from '../../../shared/pipes/section-placeholder-resolver';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-practice-pdf-layout',
  standalone: true,
  imports: [CommonModule, SectionPlaceholderResolver, TranslateModule],
  templateUrl: './practice-pdf-layout.component.html',
  styleUrl: './practice-pdf-layout.component.scss',
  providers: [DatePipe]
})
export class PracticePdfLayoutComponent {
  @Input({required: true}) practice!: Practice;

  @Input() placeholdersMap?: PlaceholderSectionElementMap[] = [];

  isSectionRefValueQuestionAndAnswerValue(sectionRefValue: unknown): sectionRefValue is { question: string; answer: string } {
    return !!sectionRefValue && typeof sectionRefValue === 'object' && 'question' in sectionRefValue;
  }
}
