

<div class="print-content">
  @for (section of practice.sections; track $index) {
      @if (isSectionRefValueQuestionAndAnswerValue(section.value)) {
      <div class="question" [innerHTML]="section.value.question | placeholderResolver : placeholdersMap"></div>
      <div class="answer" [innerHTML]="section.value.answer | placeholderResolver : placeholdersMap"></div>
    } @else {
    <div [innerHTML]="section.value | placeholderResolver : placeholdersMap"></div>
    }
  }
</div>

